<template>
    <div class="field">
        <div class="select" v-on:click="toggle" :class="{'open': open}">
            {{ currentOption.label }}
            <div v-if="!currentOption.value" class="placeholder">{{ placeholder }}</div>
        </div>
        <div class="arrow">
            <img :class="{'flip': open}" src="@/assets/arrow_down_black.svg"/>
        </div>
        <div class="options" :class="{'open': open}">
            <div class="option" v-on:click="updateValue({label: null, value: null})">-</div>
            <div class="option" v-for="option in options" v-on:click="updateValue(option)">
                {{ option.label }}
            </div>
        </div>
    </div>
</template>

<script>
    import { EventBus } from '@/event-bus'
    import { ToggleSelectField } from '@/events'

    export default {
        name: 'SelectField',

        props: [
            'value',
            'options',
            'placeholder'
        ],

        data() {
            return {
                open: false,
                currentOption: {
                    label: null,
                    value: null
                }
            }
        },

        eventListeners: [
            {
                event: ToggleSelectField,
                handler(event) {
                    if(event.component != this){
                        this.open = false
                    }
                }
            }
        ],

        methods: {
            toggle() {
                EventBus.publish(new ToggleSelectField(this))
                this.open = !this.open
            },

            updateValue(option) {
                this.currentOption = option
                if(option.value){
                    this.$emit('input', option.value.length > 0 ? option.value : null)
                } else {
                    this.$emit('input', null)
                }
                this.toggle()
            },

            reflectCurrentState() {
                var self = this
                if(this.value){
                    this.options.forEach(function(option){
                        if(option.value == self.value){
                            self.currentOption.value = self.value
                            self.currentOption.label = option.label
                        }
                    })
                } else {
                    this.currentOption = {
                        value: null,
                        label: null
                    }
                }
            }
        },

        watch: {
            value: {
                handler() {
                    this.reflectCurrentState()
                }
            },

            options: {
                handler() {
                    this.reflectCurrentState()
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .field {
        position: relative;
        border-bottom: none;
    }

    .select {
        width: 100%;
        height: 100%;
        border: 1px solid $timberGreen;
        padding: 0 20px;
        line-height: 42px;
        cursor: pointer;

        &.open {
            border-width: 2px;
            border-bottom: none;
            padding: 0 19px;
            line-height: 40px;

            .placeholder {
                line-height: 38px;
            }
        }
    }

    .placeholder {
        opacity: 0.5;
        font-style: italic;
        line-height: 40px;
    }

    .arrow {
        position: absolute;
        right: 16px;
        top: 5px;
        pointer-events: none;

        img.flip {
            transform: rotate(180deg);
        }
    }

    .options {
        position: absolute;
        top: 41px;
        display: none;
        z-index: 999;
        background: white;
        border: 2px solid $timberGreen;
        border-top-width: 1px;
        width: calc(100% - 4px);

        &.open {
            display: block;
        }

        .option {
            height: 40px;
            line-height: 42px;
            border-bottom: 1px solid $timberGreen;
            padding: 0 20px;
            cursor: pointer;

            &:last-child {
                border: none;
            }

            &:hover {
                background: $lightGrey;
            }
        }
    }
</style>
